@import "./assets/fonts/fonts.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}
