.DateRangePicker_picker__portal {
  font-family: "Roboto", sans-serif;
}

.DateInput {
  width: 110px;
}

.DateInput_input {
  font-weight: inherit;
  font-size: 1rem;
}

.DateInput_input#start_date {
  padding-left: 0;
}

.CalendarDay__default {
  border: none;
}

.CalendarDay__selected_span,
.CalendarDay__selected {
  background: #29b9da;
  border-top: 1px double white;
  border-bottom: 1px double white;
}

.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover {
  background: #1a8199;
  border: 1px double #1a8199;
}

.CalendarDay__selected_start {
  border-radius: 20px 0 0 20px;
}

.CalendarDay__selected_end {
  border-radius: 0 20px 20px 0;
}

.DayPickerNavigation_button__verticalDefault {
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.DayPickerNavigation_button__verticalDefault > svg {
  height: 24px;
  width: 24px;
}
