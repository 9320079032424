@font-face {
  font-family: 'Chambers Sans';
  src: url(./ChambersSansWeb-Medium.woff);
}

@font-face {
  font-family: 'Chambers Sans';
  src: url(./ChambersSansWeb-Black.woff);
  font-weight: bold;
}
